import { gql } from "@apollo/client"; // eslint-disable-line import/no-extraneous-dependencies

export const GET_USER_MEDIAS_STATS = gql`
  query getAllUserMediasStats {
    me {
      id
      pictureCdnUrl
      firstName
      lastName
      roles {
        name {
          title
        }
      }
      isDisabled
      disablingReason
      lives {
        totalCount
      }
      account {
        lives {
          totalCount
        }
      }
      usage {
        id
        storageSize
        currentBandwidth
        liveConsumed
        videoCount {
          userVideoCount
          accountVideoCount
        }
        mediaCount {
          userMediaCount
          accountMediaCount
        }
        audioCount {
          userAudioCount
          accountAudioCount
        }
      }
    }
  }
`;

export const GET_MEDIA_PROGRESS = gql`
  query getMediaProgress($mediaId: ID!) {
    media(id: $mediaId) {
      id
      encodingVersion
      isEncodingDone
      encodingProgress {
        id
        encodingVersion
        nbOfJobsDone
        nbOfJobsFailed
        nbOfJobsInProgress
        nbOfJobsTotal
        getThumbnailsDone
        isContentPlayable
        getMediaInfosDone
        areThumbnailsDone
        isEncodingDone
        transcriptId
        hasGetTranscript
        getTranscriptProgress
        getTranscriptLastUpdate
        getTranscriptDone
        getTranscriptFailed
      }
      versioningProgress {
        id
        encodingVersion
        nbOfJobsDone
        nbOfJobsFailed
        nbOfJobsInProgress
        nbOfJobsTotal
        getThumbnailsDone
        isContentPlayable
        getMediaInfosDone
        isEncodingDone
        areThumbnailsDone
        fileName
        transcriptId
        hasGetTranscript
        getTranscriptProgress
        getTranscriptLastUpdate
        getTranscriptDone
        getTranscriptFailed
      }
      clips {
        id
        encodingVersion
        encodingProgress {
          id
          encodingVersion
          isEncodingDone
        }
      }
    }
  }
`;

export const GET_MEDIA_INFOS = gql`
  query getMediaInfos($mediaId: ID!) {
    media(id: $mediaId) {
      id
      fileName
      selectedThumbCdnUrl
      title
      pageSetting {
        id
        pageText
        videoTitle
      }
      embedSetting {
        id
        pageText
        videoTitle
      }
    }
  }
`;

export const GET_MEDIA_DATA = gql`
  query getMediaData($mediaId: ID!) {
    media(id: $mediaId) {
      id
      note
      fileName
      createdAt
      title
      cote
      fileWidth
      fileHeight
      playlistCdnUrl
      type
      movie
      director
      duration
      author
      composer
      genre
      metaType
      date
      copyright
      comment
      xyz
      externalLink
      startsAt
      isEncodingDone
      url
      language
      producer
      postalCode
      authorPseudo
      authorEmail
      authorPhone
      authorAddress
      authorPostalCode
      authorCity
      authorCountry
      tags {
        id
        value
      }
      categories {
        id
        value
      }
      preRoll {
        id
        name
        isPublished
      }
      postRoll {
        id
        name
        isPublished
      }
      isPublished
      mediaInfos
      mediaInfos2
      selectedThumbCdnUrl
      subtitles {
        id
        languageCode
        cdnUrl
        key
      }
      publisher {
        id
        firstName
        lastName
        language
        pictureCdnUrl
        preferences {
          id
          watermarkEnabled
          watermarkPosition
          watermarkLink
        }
      }
      account {
        id
      }
      live {
        id
        replayUrl
        publisher {
          id
        }
      }
      sourceKey
      sourceCdnUrl
      clips {
        id
        title
        fileName
      }
      encodingVersion
      lastVersioning
      encodingProgress {
        id
        encodingVersion
        isEncodingDone
      }
      pageSetting {
        id
        videoTitle
        pageLogoCdnUrl
        accentColor
      }
      embedSetting {
        id
      }
      seo {
        id
      }
    }
  }
`;

export const GET_MEDIA_THUMBNAILS = gql`
  query getMediaThumbnails($mediaId: ID!) {
    media(id: $mediaId) {
      id
      type
      thumbKeys
      thumbCdnUrls
      selectedThumbKey
      selectedThumbCdnUrl
      startsAt
      encodingVersion
      publisher {
        id
      }
      account {
        id
      }
    }
  }
`;

export const GET_MEDIA_DURATION = gql`
  query getMediaDuration($mediaId: ID!) {
    media(id: $mediaId) {
      id
      duration
    }
  }
`;

export const GET_MEDIA_CREATION_DATE = gql`
  query getMediaCreationDate($mediaId: ID!) {
    media(id: $mediaId) {
      id
      createdAt
    }
  }
`;

export const GET_PLAYLIST_CREATION_DATE = gql`
  query getPlaylistCreationDate($playlistId: ID!) {
    playlist(id: $playlistId) {
      id
      createdAt
    }
  }
`;

export const GET_MEDIA_FILES_INFOS = gql`
  query getMediaFiles($mediaId: ID!) {
    media(id: $mediaId) {
      id
      files {
        id
        key
        cdnUrl
        isActive
        type
        fileName
        createdAt
        mediaId
        publisher {
          id
          firstName
          lastName
          pictureCdnUrl
        }
      }
    }
  }
`;

export const GET_ENCODING_MEDIAS = gql`
  query getEncodingMedias {
    getEncodingMedias {
      id
      encodingVersion
      fileName
      selectedThumbCdnUrl
      encodingProgress {
        id
        nbOfJobsDone
        nbOfJobsTotal
        encodingVersion
      }
    }
  }
`;

export const GET_MEDIA = gql`
  query getMedia($mediaId: ID!) {
    media(id: $mediaId) {
      id
      title
      createdAt
      duration
      isPublished
      fileName
      description
      selectedThumbCdnUrl
      isPublished
      publisher {
        id
        firstName
        lastName
        pictureCdnUrl
      }
    }
  }
`;

export const GET_MEDIA_LIVE = gql`
  query getMediaLive($mediaId: ID!) {
    media(id: $mediaId) {
      id
      live {
        id
        startedDate
        endedDate
      }
    }
  }
`;

export const GET_MEDIA_PUBLIC_PAGE_TITLE = gql`
  query getMediaPublicPageTitle($mediaId: ID!) {
    media(id: $mediaId) {
      id
      pageSetting {
        id
        pageTitle
      }
    }
  }
`;

export const GET_MEDIA_TITLE = gql`
  query getMediaTitle($mediaId: ID!) {
    media(id: $mediaId) {
      id
      title
    }
  }
`;
