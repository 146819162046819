export const shadows = {
  sidebar: "-1px 11px 20px 0px rgba(204,204,204,1)",
  navbar: "-1px 4px 18px 0px rgba(204,204,204,0.5)",
  footer: "-1px -4px 18px 0px rgba(204,204,204,0.5)",
  gridItem: "-1px 4px 18px 0px rgba(204,204,204,0.5)",
  gridItemHover: "-1px 4px 18px 0px rgba(204,204,204,1)",
  gridItemOptions: "13px 8px 27px 0px rgba(204,204,204,1)",
  listItem: "-1px 4px 18px 0px rgba(204,204,204,0.5)",
  dropdown: "-1px 4px 18px 0px rgba(204,204,204,0.5)",
  toolbar:
    "0 1px 1px rgba(0, 0, 0, 0.03), 0 2px 2px rgba(0, 0, 0, 0.03), 0 4px 4px rgba(0, 0, 0, 0.03), 0 6px 8px rgba(0, 0, 0, 0.03), 0 8px 16px rgba(0, 0, 0, 0.03);",
};

export const colors = {
  text: "#37374b",
  text_light: "#555f63",
  primary: "#f5a860",
  primary_light: "#f5a860",
  primary_light_transparent: "rgba(252, 169, 94, 0.8)",
  primary_title: "#FA675D",
  accent_red: "#fa675d",
  grey: "#acb9be",
  background: "#F8FAFB",
  backgroundWhite: "white",
  accent_green: "#63BC66",
  accent_green_transparent: "rgba(99, 188, 102, 0.8)",
  black: "black",
  blue: "#2196f3",
  red: "rgba(255, 82, 82, 1)",
  green: "#6ec78a",
  media: "#f5a860",
  iframe: "#f5a860",
  live: '#ef4a5f',
  playlist: "#74be89",
  webtv: "#26abe3",
  embed: "#5739ab"
};
