import { atom, useRecoilValue } from "recoil";

export const userState = atom({
  key: "userState",
  default: {},
});

export const usersState = atom({
  key: "usersState",
  default: [],
});

export const GetUserFromRecoil = () => {
  const user = useRecoilValue(userState);
  return user;
};
