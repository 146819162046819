import { gql } from "@apollo/client"; // eslint-disable-line import/no-extraneous-dependencies

export const GET_POLL = gql`
  query poll($id: ID!) {
    live(id: $id) {
      id
      poll {
        id
        pollQuestions{
          id
          order
          text
        }
      }
    }
  }
`;

export const GET_POLL_QUESTION = gql`
  query pollQuestion($id: ID!) {
    pollQuestion(id: $id) {
      id
      text
      type
      numberOfReplies
      questionIsPublished
      answerIsPublished
      questionStartedAt
      answerStartedAt
      allowMultiAnswers
      numberOfPersons
      pollAnswers {
        id
        text
        order
        isCorrectAnswer
        numberOfReplies
        percentageOfReplies
      }
      words {
        text
        value
      }
      poll {
        id
        pollCurrentQuestion {
          id
        }
      }
    }
  }
`;
