import { gql, Observable } from "@apollo/client"; // eslint-disable-line import/no-extraneous-dependencies
import * as Sentry from "@sentry/browser";
import { message } from "antd";
import { GraphQLError } from "graphql";
import { openNotificationWithIcon } from "../../commons/notifications";
import { handleGQLErrorWithSentry, skipError } from "../functions/sentry";
import client from "./backoffice-config";

const REFRESH_TOKEN = gql`
  mutation RefreshToken {
    refreshToken {
      accessToken
    }
  }
`;

const refreshToken = async () => {
  try {
    const refreshResolverResponse = await client.mutate({
      mutation: REFRESH_TOKEN,
    });
    const accessToken =
      refreshResolverResponse?.data?.refreshToken?.accessToken;

    localStorage.setItem("accessToken", accessToken || "");
    return accessToken;
  } catch (err) {
    localStorage.clear();
    console.log(err.message);
    throw err;
  }
};

let refreshingToken = false;
export const callbackErrorLink = ({
  graphQLErrors,
  networkError,
  operation,
  forward,
}) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      const skipSentryProcess = skipError(err);

      switch (err.extensions.code) {
        case "FORBIDDEN":
          message.error("Vous n'êtes pas autorisé à effectuer cette action.");
          break;
        case "DEGRADED_MODE":
          message.error(
            "Le service est actuellement en mode dégradé. Veuillez réessayer plus tard."
          );
          break;
        case "UNAUTHENTICATED" || "403":
          // ignore 401 error for a refresh request
          if (operation.operationName === "refreshToken") return;

          const observable = new Observable((observer) => {
            (async () => {
              try {
                if (!refreshingToken) {
                  refreshingToken = true;
                  try {
                    const accessToken = await refreshToken();

                    if (!accessToken) {
                      throw new GraphQLError("Empty AccessToken");
                    }
                  } catch (error) {
                    console.log("error", error);
                  } finally {
                    refreshingToken = false;
                  }
                } else {
                  let limit = 5;
                  while (refreshingToken) {
                    if (limit === 0) {
                      window.location.href = "/auth";
                    }
                    await new Promise((resolve) => {
                      limit--;
                      setTimeout(resolve, 500);
                    });
                  }
                }

                // Retry the failed request
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                };

                forward(operation).subscribe(subscriber);
              } catch (err) {
                observer.error(err);
              }
            })();
          });

          return observable;

        case "LIVE_LIMITATION":
          message.error(
            "Vous avez atteint la limite de direct en simultané. Si vous pensez que c'est une erreur, veuillez contacter le support."
          );
          break;
        default:
          if (!skipSentryProcess) {
            handleGQLErrorWithSentry({
              Sentry,
              error: err,
              operation,
            });
            openNotificationWithIcon("error", "graphql-error");
          }
          break;
      }
    }
  }

  if (networkError) console.log(`[Network error]: ${networkError}`);
};
