import { gql } from "@apollo/client"; // eslint-disable-line import/no-extraneous-dependencies

export const GET_LIVE_GLOBAL_DATA = gql`
  query getLiveData($liveId: ID!) {
    live(id: $liveId) {
      id
      title
      note
      createdAt
      status
      waitingThumbKey
      waitingThumbCdnUrl
      rtmpUrl
      streamKey
      previewUrl
      liveUrl
      replayUrl
      startDate
      startedDate
      endedDate
      description
      hasDVR
      hasVOD
      hasReplay
      duration
      previewStartedAt
      shouldShowCountdown
      disableAnalytic
      account {
        id
      }
      replay {
        id
        type
        title
        playlistKey
        playlistCdnUrl
        previewKey
        previewCdnUrl
        selectedThumbKey
        selectedThumbCdnUrl
        isEncodingDone
        sourceKey
        sourceCdnUrl
        mimeType
        publisher {
          id
        }
      }
      transcoder {
        broadcast_location
      }
      publisher {
        id
        firstName
        lastName
        pictureCdnUrl
      }
      discussion {
        id
      }
      liveDestinations {
        id
        active
        isCurrentlyUsed
        streamTarget {
          streamTarget_api_id
        }
        destination {
          streamUrl
          streamKey
          name
        }
      }
      embedSetting {
        id
      }
      pageSetting {
        id
        pageLogoCdnUrl
        accentColor
      }
      transcoderInfos {
        audio_codec {
          status
          text
          units
          value
        }
        connected {
          status
          text
          units
          value
        }
        bits_in_rate {
          status
          text
          units
          value
        }
        bits_out_rate {
          status
          text
          units
          value
        }
        bytes_in_rate {
          status
          text
          units
          value
        }
        bytes_out_rate {
          status
          text
          units
          value
        }
        frame_size {
          status
          text
          units
          value
        }
        frame_rate {
          status
          text
          units
          value
        }
        height {
          status
          text
          units
          value
        }
        keyframe_interval {
          status
          text
          units
          value
        }
        video_codec {
          status
          text
          units
          value
        }
        width {
          status
          text
          units
          value
        }
        targets {
          targetId
          status
        }
      }
    }
  }
`;

export const GET_LIVE_CUSTOMIZATION_DATA = gql`
  query getLiveCustomizationData($liveId: ID!) {
    live(id: $liveId) {
      id
      waitingThumbCdnUrl
      waitingThumbKey
      publisher {
        id
      }
      account {
        id
      }
    }
  }
`;

export const GET_DESTINATIONS = gql`
  query getDestinations {
    me {
      account {
        destinations {
          id
          name
          streamUrl
          streamKey
        }
      }
    }
  }
`;

export const GET_LIVE_INFOS = gql`
  query getLiveInfos($itemId: ID!) {
    live(id: $itemId) {
      id
      waitingThumbKey
      waitingThumbCdnUrl
      pageSetting {
        id
        pageTitle
        pageText
      }
      embedSetting {
        id
        pageTitle
        pageText
        pageTheme
      }
    }
  }
`;

export const GET_LIVE_THUMBNAIL = gql`
  query getLiveThumbnail($liveId: ID!) {
    live(id: $liveId) {
      id
      thumbnail
    }
  }
`;

export const GET_LIVE_STAT_INFOS = gql`
  query getLiveStatInfos($liveId: ID!) {
    live(id: $liveId) {
      id
      status
      startedDate
      endedDate
      duration
      disableAnalytic
      publisher {
        id
      }
    }
  }
`;

export const GET_LIVE_FILES = gql`
  query getLiveFileslData($liveId: ID!) {
    live(id: $liveId) {
      id
      files {
        type
        fileName
        key
        cdnUrl
        id
        isActive
      }
    }
  }
`;

export const GET_LIVE_PUBLIC_PAGE_TITLE = gql`
  query getLivePublicPageTitle($liveId: ID!) {
    live(id: $liveId) {
      id
      pageSetting {
        id
        pageTitle
      }
    }
  }
`;

export const GET_LIVE_TITLE = gql`
  query getLiveTitle($liveId: ID!) {
    live(id: $liveId) {
      id
      title
    }
  }
`;
