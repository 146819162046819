import { useMutation, useQuery } from "@apollo/client";
import {
  ARCHIVE_LIVE,
  CREATE_LIVE,
  CREATE_LIVE_FROM_MODEL,
  FALLBACK_CHECK,
  START_LIVE,
  STOP_LIVE,
  TOGGLE_LIVE_PREVIEW,
  UPDATE_LIVE,
} from "../mutations/live";
import { GET_POLL } from "../queries/interactivity";
import {
  GET_LIVE_CUSTOMIZATION_DATA,
  GET_LIVE_FILES,
  GET_LIVE_GLOBAL_DATA,
  GET_LIVE_STAT_INFOS,
  GET_LIVE_THUMBNAIL
} from "../queries/live";

/***************/
/*** Queries ***/
/***************/

export const GetLiveCustomizationData = (liveId) => {
  const { data, loading, error } = useQuery(GET_LIVE_CUSTOMIZATION_DATA, {
    variables: {
      liveId,
    },
  });

  
  const availableData = data && data.live;
  if (!availableData) {
    return {
      loading,
      hasData: availableData,
    };
  }
  return {
    availableData,
    loading,
    error,
  };
};

export const GetLiveStatInfos = (liveId, skip) => {
  const { data, loading, error } = useQuery(GET_LIVE_STAT_INFOS, {
    variables: {
      liveId,
    },
    skip,
  });
  

  return {
    live: data?.live,
    loading,
  };
};

export const GetLiveGlobalData = (liveId, skip) => {
  const {
    data,
    loading,
    error,
    refetch,
    startPolling,
    stopPolling,
    subscribeToMore,
  } = useQuery(GET_LIVE_GLOBAL_DATA, {
    variables: {
      liveId,
    },
    skip,
  });
  

  return {
    live: data?.live,
    loading,
    refetch,
    startPolling,
    stopPolling,
    subscribeToMore,
  };
};

export const GetLiveThumbnail = (liveId, skip) => {
  const { data, loading, error } = useQuery(GET_LIVE_THUMBNAIL, {
    variables: {
      liveId,
    },
    skip,
  });

  

  return {
    liveThumbnail: data?.live?.thumbnail,
    loading,
  };
};

export const GetLiveFiles = (liveId, skip) => {
  const { data, loading, error, refetch } = useQuery(GET_LIVE_FILES, {
    variables: {
      liveId,
    },
    skip,
  });
  

  return {
    live: data?.live,
    loading,
    refetch,
  };
};

/******************/
/*** Mutations ****/
/*****************/

export const useCreateLiveFromModel = () => {
  const [createLiveFromModel, { loading }] = useMutation(
    CREATE_LIVE_FROM_MODEL
  );
  return {
    createLiveFromModelLoading: loading,
    createLiveFromModel: (variables) => createLiveFromModel(variables),
  };
};

export const useCreateLive = () => {
  const [createLive, { loading }] = useMutation(CREATE_LIVE);
  return {
    createLiveLoading: loading,
    createLive: (variables) => createLive(variables),
  };
};

export const useToggleLivePreview = () => {
  const [toggleLivePreview] = useMutation(TOGGLE_LIVE_PREVIEW);
  return async (variables) => {
    try {
      return await toggleLivePreview({ variables });
    } catch (error) {
      return {
        error,
      };
    }
  };
};

export const useFallbackCheck = () => {
  const [fallbackCheck] = useMutation(FALLBACK_CHECK);
  return async (variables) => {
    try {
      return await fallbackCheck({ variables });
    } catch (error) {
      return {
        error,
      };
    }
  };
};

export const useUpdateLive = () => {
  const [updateLive] = useMutation(UPDATE_LIVE);
  return async (variables) => {
    try {
      return await updateLive({ variables });
    } catch (error) {
      return {
        error,
      };
    }
  };
};

export const useArchiveLive = () => {
  const [archiveLive] = useMutation(ARCHIVE_LIVE);
  return async (variables) => {
    // console.log(variables);

    try {
      return await archiveLive(variables);
    } catch (error) {
      return {
        error,
      };
    }
  };
};

export const useStopLive = (liveId) => {
  const [stopLive] = useMutation(STOP_LIVE, {
    refetchQueries: [
      {
        query: GET_POLL,
        variables: {
          id: liveId,
        },
      },
    ],
  });
  return async (variables) => {
    try {
      return await stopLive(variables);
    } catch (error) {
      return {
        error,
      };
    }
  };
};

export const useStartLive = () => {
  const [startLive] = useMutation(START_LIVE);
  return async (variables) => {
    try {
      return await startLive(variables);
    } catch (error) {
      return {
        error,
      };
    }
  };
};
