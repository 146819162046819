import i18n from "i18next";
import dayjs from "dayjs";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";
import "dayjs/locale/fr";

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    load: "languageOnly",
    whitelist: ["en", "fr"],
    debug: false,
    interpolation: {
      escapeValue: false,
      format: function (value, format, lng) {
        if (format === "uppercase") return value.toUpperCase();
        if (format === "3digits")
          return new Intl.NumberFormat(lng, {
            maximumSignificantDigits: 3,
          }).format(value);
        if (format === "DD/MM/YYYY") {
          return dayjs(value).format("L");
        }
        if (format === "llll") {
          return dayjs(value).format("llll");
        }
        if (format === "relative") {
          return dayjs(value).fromNow();
        }
        return value;
      },
    },
    react: {
      useSuspense: false,
    },
  });

i18n.on("languageChanged", function (lng) {
  dayjs.locale(lng);
});

export default i18n;
