import { gql } from "@apollo/client"; // eslint-disable-line import/no-extraneous-dependencies

export const CREATE_LIVE = gql`
  mutation CreateLive {
    createLive {
      id
      title
      publisher {
        id
      }
    }
  }
`;

export const CREATE_LIVE_FROM_MODEL = gql`
  mutation createLiveFromModel($liveId: ID!) {
    createLiveFromModel(liveId: $liveId) {
      id
      title
    }
  }
`;

export const UPDATE_LIVE = gql`
  mutation UpdateLive(
    $id: ID!
    $startDate: DateTime
    $startTime: String
    $endTime: String
    $description: Json
    $descriptionText: String
    $title: String
    $privacity: String
    $hasDVR: Boolean
    $hasReplay: Boolean
    $hasVOD: Boolean
    $shouldKeepDiscussion: Boolean
    $waitingThumbKey: String
    $disableAnalytic: Boolean
    $shouldShowCountdown: Boolean
    $broadcast_location: String
    $note: String
  ) {
    updateLive(
      id: $id
      startDate: $startDate
      startTime: $startTime
      endTime: $endTime
      description: $description
      descriptionText: $descriptionText
      title: $title
      privacity: $privacity
      hasDVR: $hasDVR
      hasReplay: $hasReplay
      hasVOD: $hasVOD
      shouldKeepDiscussion: $shouldKeepDiscussion
      waitingThumbKey: $waitingThumbKey
      disableAnalytic: $disableAnalytic
      shouldShowCountdown: $shouldShowCountdown
      broadcast_location: $broadcast_location
      note: $note
    ) {
      id
      title
      description
      note
      privacity
      hasDVR
      hasReplay
      hasVOD
      startDate
      tags {
        id
        value
      }
      publisher {
        id
      }
      shouldKeepDiscussion
      shouldShowCountdown
      waitingThumbKey
      waitingThumbCdnUrl
      disableAnalytic
    }
  }
`;

export const TOGGLE_LIVE_PREVIEW = gql`
  mutation toggleLivePreview($id: ID!, $state: String!) {
    toggleLivePreview(id: $id, state: $state) {
      id
      status
    }
  }
`;

export const FALLBACK_CHECK = gql`
  mutation FallbackCheck($id: ID!) {
    fallbackCheck(id: $id, fromStreamfizz: true) {
      id
      account {
        id
      }
      status
      title
      description
      liveUrl
      replayUrl
      waitingThumbCdnUrl
      liveUrl
      publisher {
        id
        ips {
          id
          label
          address
        }
        isDisabled
      }
      startDate
      replay {
        id
        isEncodingDone
        type
        fileWidth
        fileHeight
        playlistCdnUrl
        selectedThumbKey
        selectedThumbCdnUrl
        fileName
        subtitles {
          id
          languageCode
          cdnUrl
        }
        files {
          type
          cdnUrl
          id
          isActive
        }
      }
      discussion {
        id
        isEmbed
        autoPublish
      }
    }
  }
`;

export const STOP_LIVE = gql`
  mutation StopLive($id: ID!) {
    stopLive(id: $id) {
      id
      status
      endedDate
    }
  }
`;

export const START_LIVE = gql`
  mutation StartLive($id: ID!) {
    startLive(id: $id) {
      id
      status
      startedDate
    }
  }
`;

export const ARCHIVE_LIVE = gql`
  mutation archiveLive($id: ID!) {
    archiveLive(id: $id) {
      id
    }
  }
`;

export const HARD_DELETE_LIVE = gql`
  mutation DeleteLive($id: ID!) {
    deleteLive(id: $id) {
      id
    }
  }
`;