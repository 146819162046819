
// const { darkAlgorithm, compactAlgorithm } = theme;

export const antdTheme = {
  token: {
    fontFamily: "Poppins",
    colorPrimary: "#f5a860",
    fontSize: 15,
    // borderRadius: 4,
    sizeStep: 4,
  },
  components: {
    Dropdown: {
      fontSize: 14,
    },
    Select: {
      fontSize: 14,
      controlHeight: 36,
      //colorPrimary: "#f5a860",
    },
    Tooltip: {
      fontSize: 13
    },
    Button: {
      colorBorder: "#DEE2E6",
      lineHeight: 1,
      controlHeight: 36,
      fontSizeLG: 14,
      fontSize: 14,
      colorLink: "#f5a860",
      colorLinkHover: "#f5a860",
      colorLinkActive: "#f5a860",
    },
    Form: {
      controlHeight: 36,
      fontSize: 14,
    },
    Switch: {
      colorPrimary: "#6EC78A",
      colorPrimaryHover: "#6EC78A",

    },
    Input: {
      colorBgContainer: "#fff",
    },
    Card: {
      boxShadowCard:
        "0 1px 1px rgba(0, 0, 0, 0.03), 0 2px 2px rgba(0, 0, 0, 0.03), 0 4px 4px rgba(0, 0, 0, 0.03), 0 6px 8px rgba(0, 0, 0, 0.03), 0 8px 16px rgba(0, 0, 0, 0.03)",
      boxShadowTertiary:
        "   0 1px 1px rgba(0, 0, 0, 0.03), 0 2px 2px rgba(0, 0, 0, 0.03), 0 4px 4px rgba(0, 0, 0, 0.03), 0 6px 8px rgba(0, 0, 0, 0.03), 0 8px 16px rgba(0, 0, 0, 0.03)",
    },
    Tag: {
        colorBg: "#f5a860",
    },
    Modal: {
      colorBg: "#fff",
      padding: 0
    },
    Slider: {
      handleSizeHover: 12,
      handleLineWidth: 2,
      handleLineWidthHover: 3,
    },
    InputNumber: {
      colorPrimary: "#f5a860",
      colorBorder: "#DEE2E6",
    },
  },
};

// export const myTheme = () => {
//     const { token } = theme.useToken();
//     return {
//         ...token,
//         colorPrimary: "#f5a860",
//         colorBg: 'red',
//     }
// }

// @font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
// @primary-color: #f5a860;
// @font-size-base: 15px;
// @height-base: 36px;
// @border-radius-base: 4px;

// @select-dropdown-vertical-padding: 3px;
// @dropdown-vertical-padding: 10px;
// @border-color-base: #DEE2E6;
// @outline-blur-size: 2px;
// @outline-fade: 15%;
// @layout-body-background: transparent;
// @outline-width: 0;
// @btn-padding-horizontal-base: 16px;
// @btn-line-height: 16px;
// @btn-text-shadow: none;
// @btn-height-base: 36px;
// @btn-font-size-lg: 14px;
// @btn-font-size-sm: 14px;

// @form-item-label-font-size: 14px;
// @card-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 2px 2px rgba(0, 0, 0, 0.03), 0 4px 4px rgba(0, 0, 0, 0.03), 0 6px 8px rgba(0, 0, 0, 0.03), 0 8px 16px rgba(0, 0, 0, 0.03);
// @input-placeholder-color: hsv(0, 0, 75%);
// @layout-header-background: white;
// @layout-sider-background: white;
// @layout-header-padding: 0;
// @comment-font-size-sm: 12px;
// @layout-trigger-background: @primary-color;
// @form-item-margin-bottom: 15px;
// @error-color: #e5707e;
// @layout-header-height: 60px;
