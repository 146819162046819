// export const GRAPHQL_BACKOFFICE_API = "http://localhost:4242";
// export const GRAPHQL_BACKOFFICE_WS_API = "ws://localhost:4242";
export const GRAPHQL_BACKOFFICE_WS_API =
  process.env.REACT_APP_GRAPHQL_WS_URL + "/subscription";
export const GRAPHQL_BACKOFFICE_API =
  process.env.REACT_APP_GRAPHQL_URL + "/graphql";
  export const GRAPHQL_ANALYTIC_API = process.env.REACT_APP_ANALYTIC_GRAPHQL;
  
export const PLAYER_URL = process.env.REACT_APP_PLAYER_URL;

//export const GRAPHQL_STATS_API =
//"http://localhost:7071/graphql";
export const getRootUrl = (uri) => {
  if (process.env.REACT_APP_FRONT_URL) {
    return `${process.env.REACT_APP_FRONT_URL}${uri}`;
  }

  return `http://localhost:3000${uri}`;
};
