import { gql } from "@apollo/client"; // eslint-disable-line import/no-extraneous-dependencies

export const REMOVE_TAG = gql`
  mutation RemoveTag($id: ID!) {
    removeTag(id: $id) {
      id
      tags {
        id
        value
      }
      categories {
        id
        value
      }
    }
  }
`;

export const REMOVE_CATEGORY = gql`
  mutation RemoveCategory($id: ID!) {
    removeCategory(id: $id) {
      id
      tags {
        id
        value
      }
      categories {
        id
        value
      }
    }
  }
`;

export const EDIT_CATEGORY = gql`
  mutation EditCategory($id: ID!, $value: String!) {
    editCategory(id: $id, value: $value) {
      id
      value
    }
  }
`;

export const EDIT_TAG = gql`
  mutation EditTag($id: ID!, $value: String!) {
    editTag(id: $id, value: $value) {
      id
      value
    }
  }
`;


export const SET_LANGUAGE = gql`
  mutation SetLanguage($value: String!) {
    setLanguage(value: $value) {
      id
      language
    }
  }
`;


// TDOD delete this query
// export const CREATE_USER = gql`
//   mutation AddUser(
//     $email: String!
//     $firstName: String!
//     $lastName: String!
//     $language: String!
//     $personInviting: String
//   ) {
//     addUser(
//       email: $email
//       firstName: $firstName
//       lastName: $lastName
//       language: $language
//       personInviting: $personInviting
//     ) {
//       id
//     }
//   }
// `;

// TODO Create query for createAccount&owner

export const CREAT_ACCOUNT_AND_OWNER = gql`
  mutation createAccountAndOwner(
    $accountName: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $personInviting: String
  ) {
    createAccountAndOwner(
      accountName: $accountName
      firstName: $firstName
      lastName: $lastName
      email: $email
      personInviting: $personInviting
    ) {
      id
    }
  }
`;

export const LOG_AS = gql`
  mutation LogAs($email: String!) {
    logAs(email: $email) {
      accessToken
      user {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

// export const DELETE_USER = gql`
//   mutation DeleteUser($userId: ID!) {
//     deleteUser(userId: $userId) {
//       id
//       firstName
//       lastName
//       email
//       picture
//       medias {
//         mediaInfos
//         type
//         tracks {
//           id
//           language
//         }
//       }
//     }
//   }
// `;

export const UPDATE_USER = gql`
  mutation updateUser(
    $userId: String!
    $email: String
    $firstName: String
    $lastName: String
    $fileKey: String
    $roles: [RoleInput]
    $defaultPseudo: String
  ) {
    updateUser(
      userId: $userId
      email: $email
      firstName: $firstName
      lastName: $lastName
      roles: $roles
      fileKey: $fileKey
      defaultPseudo: $defaultPseudo
    ) {
      id
      firstName
      lastName
      email
      defaultPseudo
      pictureCdnUrl
      roles {
        name {
          title
        }
        permissions {
          media
          webTV
          playlist
          live
        }
      }
    }
  }
`;

// mutation updateUser($email: String, $firstName: String, $lastName: String) {
//   updateUser(email: $email, firstName: $firstName, lastName: $lastName) {
//     email
//     id
//     firstName
//     lastName
//   }
// }

export const UPDATE_COMPANY_INFOS = gql`
  mutation UpdateCompanyInfos(
    $identification: String
    $address: String
    $city: String
    $country: String
    $name: String
    $size: String
    $vat: String
    $postcode: String
  ) {
    updateCompanyInfos(
      identification: $identification
      address: $address
      city: $city
      country: $country
      name: $name
      size: $size
      vat: $vat
      postcode: $postcode
    ) {
      email
      id
      firstName
      lastName
      isOnboardingDone
      interestedFeatures
      hasKnownUsWith
      companyRole
      company {
        id
        identification
        address
        city
        country
        name
        vat
        size
      }
    }
  }
`;

/* 
export const CREATE_SUBSCRIPTION = gql`
  mutation CreateSubscription($id: String!, $modules: [String!]!) {
    createSubscription(id: $id, modules: $modules) {
      id
      object
      currency
      sources {
        total_count
        data {
          id
          client_secret
          usage
          owner {
            name
          }
          card {
            exp_month
            exp_year
            brand
            funding
            last4
          }
        }
      }
      subscriptions {
        total_count
        data {
          id
          cancel_at
          created
          current_period_start
          current_period_end
          plan {
            id
            active
            nickname
            trial_period_days
          }
        }
      }
    }
  }
`;

export const UPDATE_SUBSCRIPTION = gql`
  mutation UpdateSubscription($id: String!, $modules: [String!]!) {
    updateSubscription(id: $id, modules: $modules) {
      id
      object
      currency
      sources {
        total_count
        data {
          id
          client_secret
          usage
          owner {
            name
          }
          card {
            exp_month
            exp_year
            brand
            funding
            last4
          }
        }
      }
      subscriptions {
        total_count
        data {
          id
          cancel_at
          created
          current_period_start
          current_period_end
          plan {
            id
            active
            nickname
            trial_period_days
          }
        }
      }
    }
  }
`;

export const CANCEL_SUBSCRIPTION = gql`
  mutation CancelSubscription($modules: [String!]!) {
    cancelSubscription(modules: $modules) {
      id
      object
      currency
      sources {
        total_count
        data {
          id
          client_secret
          usage
          owner {
            name
          }
          card {
            exp_month
            exp_year
            brand
            funding
            last4
          }
        }
      }
      subscriptions {
        total_count
        data {
          id
          cancel_at
          created
          current_period_start
          current_period_end
          plan {
            id
            active
            nickname
            trial_period_days
          }
        }
      }
    }
  }
`; */

export const UPDATE_ACCOUNT_PREFERENCES = gql`
  mutation UpdateAccountPreferences(
    $pageTheme: String
    $accentColor: String
    $shouldShowSummary: Boolean
    $shouldShowShareButton: Boolean
    $shouldShowTranscript: Boolean
    $shouldShowChapter: Boolean
    $shouldShowFile: Boolean
    $shouldShowPoll: Boolean
    $vodIsPublished: Boolean
    $vodPrivacity: String
    $vodSelectedThumbKey: String
    $liveIsPublished: Boolean
    $livePrivacity: String
    $liveSelectedThumbKey: String
    $watermarkEnabled: Boolean
    $watermarkPosition: String
    $watermarkLink: String
    $watermarkImageKey: String
  ) {
    updateAccountPreferences(
      pageTheme: $pageTheme
      accentColor: $accentColor
      shouldShowSummary: $shouldShowSummary
      shouldShowShareButton: $shouldShowShareButton
      shouldShowTranscript: $shouldShowTranscript
      shouldShowChapter: $shouldShowChapter
      shouldShowFile: $shouldShowFile
      shouldShowPoll: $shouldShowPoll
      vodIsPublished: $vodIsPublished
      vodPrivacity: $vodPrivacity
      vodSelectedThumbKey: $vodSelectedThumbKey
      liveIsPublished: $liveIsPublished
      livePrivacity: $livePrivacity
      liveSelectedThumbKey: $liveSelectedThumbKey
      watermarkEnabled: $watermarkEnabled
      watermarkPosition: $watermarkPosition
      watermarkLink: $watermarkLink
      watermarkImageKey: $watermarkImageKey
    ) {
      id
      pageTheme
      accentColor
      shouldShowSummary
      shouldShowShareButton
      shouldShowTranscript
      shouldShowChapter
      shouldShowFile
      shouldShowPoll
      vodIsPublished
      vodPrivacity
      vodSelectedThumbKey
      vodSelectedThumbCdnUrl
      liveIsPublished
      livePrivacity
      liveSelectedThumbKey
      liveSelectedThumbCdnUrl
      watermarkEnabled
      watermarkPosition
      watermarkLink
      watermarkImageKey
      watermarkImageCdnUrl
    }
  }
`;

export const UPDATE_PREFERENCES = gql`
  mutation UpdatePreferences(
    $pageTheme: String
    $accentColor: String
    $shouldShowSummary: Boolean
    $shouldShowShareButton: Boolean
    $shouldShowTranscript: Boolean
    $shouldShowChapter: Boolean
    $shouldShowFile: Boolean
    $shouldShowPoll: Boolean
    $vodIsPublished: Boolean
    $vodPrivacity: String
    $vodSelectedThumbKey: String
    $liveIsPublished: Boolean
    $livePrivacity: String
    $liveSelectedThumbKey: String
    $watermarkEnabled: Boolean
    $watermarkPosition: String
    $watermarkLink: String
    $watermarkImageKey: String
  ) {
    updatePreferences(
      pageTheme: $pageTheme
      accentColor: $accentColor
      shouldShowSummary: $shouldShowSummary
      shouldShowShareButton: $shouldShowShareButton
      shouldShowTranscript: $shouldShowTranscript
      shouldShowChapter: $shouldShowChapter
      shouldShowFile: $shouldShowFile
      shouldShowPoll: $shouldShowPoll
      vodIsPublished: $vodIsPublished
      vodPrivacity: $vodPrivacity
      vodSelectedThumbKey: $vodSelectedThumbKey
      liveIsPublished: $liveIsPublished
      livePrivacity: $livePrivacity
      liveSelectedThumbKey: $liveSelectedThumbKey
      watermarkEnabled: $watermarkEnabled
      watermarkPosition: $watermarkPosition
      watermarkLink: $watermarkLink
      watermarkImageKey: $watermarkImageKey
    ) {
      id
      pageTheme
      accentColor
      shouldShowSummary
      shouldShowShareButton
      shouldShowTranscript
      shouldShowChapter
      shouldShowFile
      shouldShowPoll
      vodIsPublished
      vodPrivacity
      vodSelectedThumbKey
      vodSelectedThumbCdnUrl
      liveIsPublished
      livePrivacity
      liveSelectedThumbKey
      liveSelectedThumbCdnUrl
      watermarkEnabled
      watermarkPosition
      watermarkLink
      watermarkImageKey
      watermarkImageCdnUrl
    }
  }
`;

export const UPDATE_DEFAULT_PSEUDO = gql`
  mutation updateDefaultUser($defaultPseudo: String!, $userId: String) {
    updateDefaultPseudo(defaultPseudo: $defaultPseudo, userId: $userId) {
      id
      defaultPseudo
    }
  }
`;

export const UPDATE_ME = gql`
  mutation updateMe(
    $firstName: String
    $lastName: String
    $email: String
    $defaultPseudo: String
    $fileKey: String
  ) {
    updateMe(
      firstName: $firstName
      lastName: $lastName
      email: $email
      defaultPseudo: $defaultPseudo
      fileKey: $fileKey
    ) {
      id
      firstName
      lastName
      email
      defaultPseudo
      pictureCdnUrl
    }
  }
`;

export const CHANGE_OWNER = gql`
  mutation changeOwner(
    $newOwnerId: String!
    $contentType: String!
    $contentId: String!
    $isBatch: Boolean
  ) {
    changeOwner(
      newOwnerId: $newOwnerId
      contentType: $contentType
      contentId: $contentId
      isBatch: $isBatch
    ) {
      id
    }
  }
`;