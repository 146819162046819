import styled from "@emotion/styled";
import * as Sentry from "@sentry/browser";
import { Component } from "react";

import errorPoster from "../styles/icons/error-icon.svg";
import onboardingBackground from "../styles/images/onboardingBackground.svg";

import { colors } from "../styles/variables";
import { getSentryEnabled } from "../utils/functions/sentry";
import { withTranslation } from "../utils/withTranslation";
import { Button, HR } from "./components";

const ReporterMain = styled("div")`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
`;
const ReporterForm = styled("div")`
  display: flex;
  flex-direction: column;
  width: 40%;
  min-width: 200px;
  padding-left: 2%;
  padding-right: 6%;
  padding-top: 2%;
  padding-bottom: 10%;
`;
const ReporterDisplay = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: top;
  width: 60%;
  background-image: url(${onboardingBackground});
  background-size: cover;
  color: ${colors.backgroundWhite};
  position: relative;
`;

const ReporterImage = styled("img")`
  width: 30%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Logo = styled("div")`
  font-size: 40px;
  margin-top: 40px;
  padding: 0;
  font-weight: bold;
  color: black;
  span {
    color: #f5a860;
  }
`;

const FormTitle = styled("h3")`
  font-size: 26px;
  margin-bottom: 15px;
`;
const FormSubtitle = styled("h4")`
  margin-top: 10px;
  margin-bottom: 50px;
  font-size: 20px;
  color: ${colors.text_light};
  font-weight: normal;
`;

const SpacedRow = styled("div")`
  display: flex;
  flex-direction: row;
  margin-bottom: 25.5px;
`;

const ErrorText = styled("p")`
  font-size: 18px;
`;

const ChoiceText = styled("p")`
  margin-top: 0px;
  margin-bottom: 20px;
  font-size: 18px;
  color: ${colors.text_light};
  font-weight: normal;
`;

const ErrorCode = styled("span")`
  border: 1px solid #ccc;
  padding: 20px 40px;
  margin-top: 10px;
  background: #eee;
  display: block;
`;

const ReporterButton = styled(Button)`
  margin-left: 0;
  margin-right: 30px;
  background-color: ${colors.primary};
`;

class SentryReporter extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, eventId: null };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    const sentryEnabled = getSentryEnabled();
    if (!sentryEnabled) return;
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      if (!error.message.includes("Invalid password")) {
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      }
    });
  }

  render() {
    const { t } = this.props;
    if (this.state.error) {
      // render fallback UI
      return (
        <ReporterMain>
          <ReporterDisplay>
            <ReporterImage src={errorPoster} />
          </ReporterDisplay>
          <ReporterForm>
            <Logo>
              Stream<span>Fizz</span>
            </Logo>
            <FormTitle>{t("Sentry:Error!")}</FormTitle>
            <FormSubtitle>
              {t("Sentry:We are sorry, something unexpected happened.")}
            </FormSubtitle>
            <HR />
            <ChoiceText>{t("Sentry:What do you want to do?")}</ChoiceText>
            <SpacedRow>
              <ReporterButton
                onClick={() => {
                  this.props.history.replace("/");
                  window.location.reload();
                }}
              >
                {t("Sentry:Go back to the home page")}
              </ReporterButton>

              <ReporterButton
                onClick={() => {
                  Sentry.showReportDialog({
                    title: t(
                      "Sentry:We are sorry, something unexpected happened."
                    ),
                    eventId: this.state.eventId,
                  });
                }}
              >
                {t("Sentry:send us this error")}
              </ReporterButton>
            </SpacedRow>
            <HR />
            <p>
              <ErrorText>
                {t("Sentry:Error message")} <br />
                <ErrorCode>
                  <code>{this.state.error.toString()}</code>
                </ErrorCode>
              </ErrorText>
            </p>
          </ReporterForm>
        </ReporterMain>
      );
    }
    // when there's not an error, render children untouched
    return this.props.children;
  }
}

export default withTranslation("Sentry")(SentryReporter)
