import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import TextareaAutosize from "react-autosize-textarea";
import { colors, shadows } from "../styles/variables";

const gcd = (a, b) => {
  if (b) {
    return gcd(b, a % b);
  } else {
    return Math.abs(a);
  }
};

export const HR = styled("hr")`
  background-color: #ddd;
  border: none;
  width: 100%;
  height: 2px;
  margin-bottom: 25.5px;
`;

export const Button = styled("button")`
  border: none;
  box-shadow: none;
  position: relative;
  border-radius: 4px;
  background-color: ${(props) =>
    props.disabled ? "#ddd" : colors.primary_light};
  color: ${colors.backgroundWhite};
  margin-left: ${(props) => (props.isForm ? 0 : "10px")};
  margin-right: 10px;
  min-height: 42px;
  min-width: 100px;
  cursor: ${(props) => (props.disabled ? "initial" : "pointer")};
  &:focus {
    outline: none;
  }
`;

export const Label = styled("label")`
  display: block;
  color: #495057;
  font-size: 14px;
  margin-bottom: 10px;
`;

const FormElem = styled("div")`
  position: relative;
  width: ${(props) => (props.width ? props.width : "100%")};
  margin-bottom: ${(props) =>
    props.top
      ? "10px"
      : props.margin === "top" || props.contact || props.properties
      ? "0"
      : "20px"};
  margin-top: ${(props) => (props.margin === "top" ? "20px" : "0")};
  ${(props) => props.noMargin && "margin: 0"};
  margin-left: ${(props) => props.contact || (props.webTv && "15px")};
  margin-right: ${(props) => props.discussion && "15px"};
  align-self: ${(props) => props.flexEnd && "flex-end"};
`;


const InputError = styled("p")`
  font-size: 14px;
  font-weight: 600;
  color: ${colors.accent_red} !important;
  margin: 8px 0 0 8px;
`;

const CustomTextarea = styled("div")`
  textarea {
    position: relative;
    border-radius: 4px;
    background: ${(props) => props?.theme?.colors?.backgroundField};
    box-shadow: none;
    padding: ${(props) => (props.noPadding ? "0" : "12px 18px")};
    min-height: 38px;
    color: ${(props) => {
      if (props.theme) return `${props.theme?.colors?.text}`;
      return `${colors.text}`;
    }};
    outline: none;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    height: ${(props) => (props.height ? props.height : "auto")};
    resize: none;
    &:focus {
      border-color: ${(props) => props.accentColor || colors.primary};
    }
  }
`;

const TextareaCounter = styled("span")`
  font-size: 10px;
  color: #737373;
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px;
  z-index: 1;
`;

export const Textarea = ({
  width,
  height,
  label,
  inputProps,
  noPadding = false,
  noMargin = false,
  theme,
  accentColor,
  error,
  isError = false,
  white = false,
  className,
  maxChar,
}) => {
  const [count, setCount] = useState(
    inputProps.defaultValue ? inputProps.defaultValue.length : 0
  );

  useEffect(() => {
    if (!inputProps.defaultValue || !maxChar) return;

    setCount(inputProps.defaultValue.length);
    // FIXME add dependencies ?
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputProps]);

  useEffect(() => {
    if (count > maxChar) {
    }
    // FIXME add dependencies ?
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  return (
    <FormElem className={className} width={width} noMargin={noMargin}>
      {label && <Label theme={theme}>{label}</Label>}
      <CustomTextarea
        white={white}
        accentColor={accentColor}
        theme={theme}
        height={height}
        noPadding={noPadding}
      >
        {maxChar && (
          <TextareaCounter>
            {count}/{maxChar}
          </TextareaCounter>
        )}
        <TextareaAutosize {...inputProps} maxLength={maxChar ? maxChar : ""} />
      </CustomTextarea>

      {isError && <InputError>{error}</InputError>}
    </FormElem>
  );
};

export const ButtonBack = styled("div")`
  position: fixed;
  bottom: 30px;
  left: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  background-color: ${colors.primary};
  box-shadow: ${shadows.gridItem};
  cursor: pointer;
  z-index: 9999;
  svg {
    width: 30px;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(45%, 45%);
  }
`;

export const SelectContainer = styled("label")`
  display: flex;
  flex-direction: column;
  > span {
    font-size: 14px;
    color: #495057;
  }
  > div {
    margin-top: 4px;
  }
`;

export const SwitchStyle = styled("div")`
  display: flex;

  .ant-switch-inner {
    padding: 0 !important;
  }
  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
  }
  .ant-form-item {
    margin-bottom: 0px;
    span {
      margin: 0 10px;
      align-items: center;
      align-content: center;
    }
  }
  .ant-form-item-extra {
    font-size: 14px;
    line-height: 1.3;
    margin: 0 0 0 54px;
  }
  .ant-form-item-control-input {
    min-height: inherit !important;
  }
  margin-bottom: 20px;
  --antd-wave-shadow-color: #4ad763 !important;
  .ant-switch-checked {
    --antd-wave-shadow-color: #babbbc !important;
  }
`;
