
const errorsToSkip = [
  "No refresh token provided.",
  "Invalid password",
  "No User found",
];

const front_envs = [
  "beta",
  "development",
  "production",
];

export const getSentryEnabled = () => {
  return front_envs.includes(process.env.REACT_APP_ENVIRONMENT);
}

export const skipError = (error) => {
  return errorsToSkip.some((err) => error.message.includes(err));
}

export const handleGQLErrorWithSentry = ({ Sentry, error, operation }) => {
  const sentryEnabled = getSentryEnabled();
  if (!sentryEnabled) return;
  Sentry.withScope((scope) => {
    scope.setTransactionName(operation.operationName);
    scope.setContext("apolloGraphQLOperation", {
      operationName: operation.operationName,
      variables: operation.variables,
      extensions: operation.extensions,
    });

    Sentry.captureMessage(error.message, {
      level: Sentry.Severity.Error,
      fingerprint: ["{{ default }}", "{{ transaction }}"],
      contexts: {
        apolloGraphQLError: {
          error,
          message: error.message,
          extensions: error.extensions,
        },
      },
    });
  });
};
