import styled from "@emotion/styled";
import React, { Suspense, useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useRecoilState } from "recoil";
import { ButtonBack } from "../commons/components";
import LoaderSpin from "../commons/components/LoaderSpin";
import { GetUserFromRecoil, userState, usersState } from "../recoil/user";
import { ReactComponent as BackIcon } from "../styles/icons/back-icon.svg";
import { colors } from "../styles/variables";
import { isJustModerator } from "../utils/functions/getRolesPermission";
import client from "../utils/graphQLConfig/backoffice-config.js";
import { GetUserData } from "../utils/store/user";
import { withTranslation } from "../utils/withTranslation";
import Landing from "./landing/Landing";
// import Styleguide from "./backoffice/styleguide/styleguide";
//retry lazy imports up to 4 times if they fail
export const retry = (fn, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        console.error(error);
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

const BackOffice = React.lazy(() => retry(() => import("./backoffice")));
const Auth = React.lazy(() => retry(() => import("./Auth")));
const VideoPage = React.lazy(() =>
  retry(() => import("./generated/VideoPage"))
);
const EmbedPage = React.lazy(() => retry(() => import("./generated/Embed")));
const Management = React.lazy(() => retry(() => import("./management")));
const LivePage = React.lazy(() => retry(() => import("./generated/Live")));
const EmbedLivePage = React.lazy(() =>
  retry(() => import("./generated/EmbedLive"))
);
const WebTV = React.lazy(() => retry(() => import("./generated/WebTV/index")));
const Discussion = React.lazy(() =>
  retry(() => import("./generated/Questionnaire"))
);
const Animateur = React.lazy(() =>
  retry(() => import("./generated/Animateur"))
);
const ModeratorPage = React.lazy(() =>
  retry(() => import("../commons/Moderator/Details/index"))
);
const ModeratorHome = React.lazy(() =>
  retry(() => import("../commons/Moderator/"))
);
const BodyMain = styled("main")`
  background-color: ${colors.background};
`;

const ProtectedRoute = ({
  isAllowed,
  shouldSkipUserData,
  loading,
  redirectPath = "/auth",
  children,
  roles,
}) => {
  if (loading || shouldSkipUserData) {
    return <LoaderSpin />;
  }
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }
  if (isJustModerator(roles)) {
    return <Navigate to="/moderation" replace />;
  }

  return children;
};

const Body = (props) => {
  const [shouldSkipUserData, setShouldSkipUserData] = useState(true);
  const { i18n } = props;
  const [user, setUser] = useRecoilState(userState);
  const [users, setUsers] = useRecoilState(usersState);

  let hasLocalStorage = true;
  const location = useLocation();
  const navigate = useNavigate();

  const { data, loading, refetch } = GetUserData(shouldSkipUserData);
  const { id, roles } = GetUserFromRecoil();

  useEffect(() => {
    if (!data || loading) return;
    if (i18n.language !== data?.language) {
      i18n.changeLanguage(data.language);
    }
    setUser(data);
    setUsers(data?.account?.users);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (
      location.pathname.includes("auth" || "reset-password" || "set-password")
    ) {
      setShouldSkipUserData(true);
    } else {
      setShouldSkipUserData(false);
    }
  }, [location]);

  try {
    // eslint-disable-next-line no-unused-vars
    const sampleRead = window.localStorage;
  } catch (e) {
    hasLocalStorage = false;
  }

  if (loading) {
    return <LoaderSpin />;
  }

  return (
    <BodyMain>
      <link
        crossOrigin={true}
        href={`https://${process.env.REACT_APP_ALGOLIA_APP_ID}-dsn.algolia.net`}
        rel="preconnect"
      />
      {hasLocalStorage &&
        window.localStorage.getItem("authMasterToken") &&
        !location.pathname.includes("management") && (
          <ButtonBack
            onClick={async () => {
              window.localStorage.setItem(
                "accessToken",
                window.localStorage.getItem("authMasterToken")
              );
              window.localStorage.setItem("authMasterToken", "");
              window.localStorage.removeItem("algoliaKey");
              refetch().then(() => {
                navigate("/management/users");
                client.onResetStore();
              });
            }}
          >
            <BackIcon />
          </ButtonBack>
        )}

      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<LoaderSpin />}>
              <Landing />
            </Suspense>
          }
        />
        <Route
          path="/auth"
          element={
            <Suspense fallback={<LoaderSpin />}>
              <Auth type="auth" />
            </Suspense>
          }
        />

        <Route
          path="/reset-password"
          element={
            <Suspense fallback={<LoaderSpin />}>
              <Auth type="reset-password" />
            </Suspense>
          }
        />

        <Route
          path="/set-password"
          element={
            <Suspense fallback={<LoaderSpin />}>
              <Auth type="set-password" />
            </Suspense>
          }
        />

        <Route
          path="/app/*"
          element={
            <ProtectedRoute
              loading={loading}
              shouldSkipUserData={shouldSkipUserData}
              isAllowed={!!data?.id}
              roles={user.roles}
            >
              <Suspense fallback={<LoaderSpin />}>
                <BackOffice />
              </Suspense>
            </ProtectedRoute>
          }
        />

        <Route
          path="/management/*"
          element={
            <Suspense fallback={<LoaderSpin />}>
              <Management />
            </Suspense>
          }
        />

        {/* DO NOT REMOVE
      LEGACY REDIRECT FROM OLD PLATER TO NEW PLATER */}
        <Route
          path="/media/:mediaId"
          element={
            <Suspense fallback={<LoaderSpin />}>
              <VideoPage isPlaylist={false} />
            </Suspense>
          }
        />

        <Route
          path="/playlist/:playlistId"
          element={
            <Suspense fallback={<LoaderSpin />}>
              <VideoPage isPlaylist={true} />
            </Suspense>
          }
        />

        <Route
          path="/embed/:mediaId"
          element={
            <Suspense fallback={<LoaderSpin isFull={true} />}>
              <EmbedPage isPlaylist={false} />
            </Suspense>
          }
        />

        <Route
          path="/embedp/:playlistId"
          element={
            <Suspense fallback={<LoaderSpin isFull={true} />}>
              <EmbedPage isPlaylist={true} />
            </Suspense>
          }
        />

        <Route
          path="/live/:liveId"
          element={
            <Suspense fallback={<LoaderSpin />}>
              <LivePage />
            </Suspense>
          }
        />

        <Route
          path="/embedl/:liveId"
          element={
            <Suspense fallback={<LoaderSpin isFull={true} />}>
              <EmbedLivePage />
            </Suspense>
          }
        />

        <Route
          path="/webTV/:WebTVId"
          element={
            <Suspense fallback={<LoaderSpin />}>
              <WebTV />
            </Suspense>
          }
        />

        <Route
          path="/questionnaire/:discussionId"
          element={
            <Suspense fallback={<LoaderSpin />}>
              <Discussion />
            </Suspense>
          }
        />

        <Route
          path="/animateur/:discussionId"
          element={
            <Suspense fallback={<LoaderSpin />}>
              <Animateur />
            </Suspense>
          }
        />
        <Route
          path="/moderation"
          element={
            <Suspense fallback={<LoaderSpin />}>
              <ModeratorHome />
            </Suspense>
          }
        />
        <Route
          path="/moderation/:discussionId"
          element={
            <Suspense fallback={<LoaderSpin />}>
              <ModeratorPage />
            </Suspense>
          }
        />
      </Routes>
    </BodyMain>
  );
};

export default withTranslation()(Body);
