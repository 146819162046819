import { useMutation, useQuery } from "@apollo/client";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userState } from "../../recoil/user";
import {
  CHANGE_OWNER,
  SET_LANGUAGE,
  UPDATE_COMPANY_INFOS,
  UPDATE_DEFAULT_PSEUDO,
  UPDATE_ME,
  UPDATE_USER,
} from "../mutations/user";
import {
  GET_ALL_USERS,
  GET_CUSTOMERS_INFOS,
  GET_CUSTOMER_INFOS,
  GET_USER_BY_ROLES,
  GET_USER_DATA,
  GET_USER_SETTINGS_INFOS,
} from "../queries/user";

/***************/
/*** Queries ***/
/***************/

export const GetUserData = (skip = false) => {
  const { data, loading, error, refetch } = useQuery(GET_USER_DATA, {
    skip,
  });
  const availableData = data?.me;

  return {
    data: availableData,
    loading,
    error,
    refetch,
  };
};

export const GetUserSettingsInfos = () => {
  const { data, loading, error, refetch } = useQuery(GET_USER_SETTINGS_INFOS);

  const availableData = data?.me;
  return {
    language: availableData?.language,
    userSettings: availableData,
    loading,
    refetch,
  };
};

export const GetUsersByRoles = (roleNames, skip) => {
  const { data, loading, error } = useQuery(GET_USER_BY_ROLES, {
    variables: {
      roleNames,
    },
    skip,
  });

  const availableData = data?.getUserByRoles;
  return {
    data: availableData,
    loadingUserByRoles: loading,
    error,
  };
};

export const GetCustomersInfos = () => {
  const { data, loading, error } = useQuery(GET_CUSTOMERS_INFOS);

  const availableData = data && data?.customersInfo;

  return {
    customersInfo: availableData,
    loading,
  };
};

export const GetCustomerInfos = (skip) => {
  const { data, loading, error } = useQuery(GET_CUSTOMER_INFOS, { skip });

  const availableData = data && data?.customerInfo;
  return {
    customerInfo: availableData,
    loading,
  };
};

export const GetAllUsers = () => {
  const { data, loading, error } = useQuery(GET_ALL_USERS, {
    fetchPolicy: "no-cache",
  });

  const availableData = data && data?.users;

  return {
    users: availableData,
    usersLoading: loading,
  };
};

/*****************/
/*** Mutations ***/
/*****************/

export const useSetLanguage = () => {
  const [setLanguage] = useMutation(SET_LANGUAGE);

  return async (value) => {
    try {
      return await setLanguage({
        variables: {
          value,
        },
      });
    } catch (error) {
      return { error };
    }
  };
};

export const useUpdateCompanyInfos = () => {
  const [updateCompanyInfos] = useMutation(UPDATE_COMPANY_INFOS);

  return async (variables) => {
    try {
      return await updateCompanyInfos(variables);
    } catch (error) {
      return { error };
    }
  };
};

export const useUpdateUser = () => {
  const [updateUser] = useMutation(UPDATE_USER);
  const oldUser = useRecoilValue(userState);
  const setUser = useSetRecoilState(userState);

  return async (user) => {
    const variables = { ...user };
    try {
      const resp = await updateUser({ variables });
      const updatedUser = resp.data.updateUser.updatedUser;

      if (user.userId === oldUser.userId) {
        const newUser = {
          ...oldUser,
          firstName: updatedUser.firstName,
          lastName: updatedUser.lastName,
          roles: updatedUser.role,
        };
        setUser(newUser);
      }
      return resp;
    } catch (error) {
      return { error };
    }
  };
};

export const useUpdateDafaultPseudo = () => {
  const [updateDefaultPseudo] = useMutation(UPDATE_DEFAULT_PSEUDO);

  return async (variables) => {
    try {
      return await updateDefaultPseudo(variables);
    } catch (error) {
      return { error };
    }
  };
};

export const useUpdateMe = () => {
  const [updateMe, { loading, error }] = useMutation(UPDATE_ME);
  return {
    loading,
    error,
    updateMe: (variables) => updateMe({ variables }),
  };
};

export const useChangeOwner = () => {
  const [changeOwner, {loading}] = useMutation(CHANGE_OWNER);

  return {
    changeOwner: (variables) => changeOwner({ variables }),
    loading,
  };
};
