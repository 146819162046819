// i18next-extract-disable
import React from "react";
import { useTranslation as useT } from "react-i18next";
import Skeleton from "../commons/Skeleton/skeleton";

const CustomT = (value, ops, t, ready) => {
  return ready ? t(value, ops) : <Skeleton />;
};

export function withTranslation(ns, options = {}) {
  return function Extend(WrappedComponent) {
    function I18nextWithTranslation({ forwardedRef, ...rest }) {
      const { t, i18n, ready } = useT(ns, { ...rest, useSuspense: false });

      const passDownProps = {
        ...rest,
        t: (value, ops = {}) => CustomT(value, ops, t, ready),
        i18n,
        tReady: ready,
      };
      if (options.withRef && forwardedRef) {
        passDownProps.ref = forwardedRef;
      } else if (!options.withRef && forwardedRef) {
        passDownProps.forwardedRef = forwardedRef;
      }
      return React.createElement(WrappedComponent, passDownProps);
    }

    I18nextWithTranslation.WrappedComponent = WrappedComponent;

    const forwardRef = (props, ref) =>
      React.createElement(
        I18nextWithTranslation,
        Object.assign({}, props, { forwardedRef: ref })
      );

    return options.withRef
      ? React.forwardRef(forwardRef)
      : I18nextWithTranslation;
  };
}
