import { gql } from "@apollo/client"; // eslint-disable-line import/no-extraneous-dependencies

export const GET_USER_LANGUAGE = gql`
  query loggedInUserQuery {
    me {
      id
      language
      roles {
        name {
          title
        }
        permissions {
          media
          playlist
          live
          webTV
        }
      }
    }
  }
`;

export const GET_USER_NAME = gql`
  query getUserNameQuery {
    me {
      id
      firstName
      lastName
      language

      account {
        id
      }
    }
  }
`;

export const GET_USER_DATA = gql`
  query getUserData {
    me {
      id
      email
      firstName
      lastName
      language
      isOnboardingDone
      defaultPseudo
      pictureCdnUrl
      tours {
        id
        name
        isDone
      }
      roles {
        name {
          title
        }
        permissions {
          media
          playlist
          live
          webTV
        }
      }
      account {
        id
        company {
          id
          name
        }
        users {
          id
          pictureCdnUrl
          email
          firstName
          lastName
        }
      }
      usage {
        mediaCount {
          userMediaCount
        }
      }
      notificationCount
    }
  }
`;

export const GET_USER_SETTINGS_INFOS = gql`
  query getUserSettingsInfoQuery {
    me {
      id
      firstName
      lastName
      email
      language
      pictureCdnUrl
      defaultPseudo
      tours {
        id
        name
        isDone
      }
      account {
        company {
          id
          identification
          address
          city
          country
          name
          vat
          postcode
        }
      }
      roles {
        name {
          title
        }
        permissions {
          media
          playlist
          live
          webTV
        }
      }
    }
  }
`;

export const GET_USER_TAGS_CATEGORIES = gql`
  query getUserAllowedIps {
    me {
      id
      tags {
        id
        value
      }
      categories {
        id
        value
      }
    }
  }
`;

export const GET_ALL_USERS = gql`
  query getAllUsersQuery {
    users {
      account {
        id
        company {
          name
        }
        users {
          id
        }
      }
      id
      firstName
      lastName
      email
      roles {
        name {
          title
        }
      }
      usage {
        id
        storageSize
        currentBandwidth
        liveConsumed
        videoCount {
          userVideoCount
          accountVideoCount
        }
        mediaCount {
          userMediaCount
          accountMediaCount
        }
        audioCount {
          userAudioCount
          accountAudioCount
        }
      }
    }
  }
`;

export const GET_CUSTOMER_INFOS = gql`
  query getCustomerInfos {
    customerInfo {
      id
      object
      currency
      metadata {
        hasLive
        hasVod
        hasTranscript
        maxStorage
        maxBandwidth
        maxDuration
        maxViewers
        transcriptCredit
      }
      sources {
        total_count
        data {
          id
          client_secret
          usage
          owner {
            name
          }
          type
          sepa_debit {
            mandate_url
            country
            last4
          }
          card {
            exp_month
            exp_year
            brand
            funding
            last4
          }
        }
      }
      subscriptions {
        total_count
        data {
          id
          cancel_at
          created
          current_period_start
          current_period_end
          items {
            total_count
            data {
              id
              cancel_at
              created
              current_period_start
              current_period_end
              plan {
                id
                active
                nickname
                trial_period_days
                metadata {
                  hasLive
                  hasVod
                  maxStorage
                  maxBandwidth
                  maxDuration
                  maxViewers
                  transcriptCredit
                }
              }
            }
          }
          plan {
            id
            active
            nickname
            trial_period_days
            metadata {
              hasLive
              hasVod
              maxStorage
              maxBandwidth
              maxDuration
              maxViewers
              transcriptCredit
            }
          }
        }
      }
    }
  }
`;

export const GET_CUSTOMERS_INFOS = gql`
  query getCustomersInfos {
    customersInfo {
      id
      object
      currency
      sources {
        total_count
        data {
          id
          client_secret
          usage
          owner {
            name
          }
          card {
            exp_month
            exp_year
            brand
            funding
            last4
          }
        }
      }
      subscriptions {
        total_count
        data {
          id
          cancel_at
          created
          current_period_start
          current_period_end
          items {
            total_count
            data {
              id
              cancel_at
              created
              current_period_start
              current_period_end
              plan {
                id
                active
                nickname
                trial_period_days
                metadata {
                  hasLive
                  hasVod
                  maxStorage
                  maxBandwidth
                  maxDuration
                  maxViewers
                  transcriptCredit
                }
              }
            }
          }
          plan {
            id
            active
            nickname
            trial_period_days
            metadata {
              hasLive
              hasVod
              maxStorage
              maxBandwidth
              maxDuration
              maxViewers
              transcriptCredit
            }
          }
        }
      }
    }
  }
`;

export const GET_ACCOUNT_PREFERENCES = gql`
  query getAccountPreferences {
    me {
      id
      account {
        id
        preferences {
          id
          pageLogoKey
          pageTheme
          accentColor
          shouldShowSummary
          shouldShowShareButton
          shouldShowTranscript
          shouldShowChapter
          vodIsPublished
          vodPrivacity
          vodSelectedThumbKey
          vodSelectedThumbCdnUrl
          liveIsPublished
          livePrivacity
          liveSelectedThumbKey
          liveSelectedThumbCdnUrl
          watermarkEnabled
          watermarkPosition
          watermarkLink
          watermarkImageKey
          watermarkImageCdnUrl
          pageSetting {
            id
            startsAt
            shouldShowSummary
            shouldShowDiscussion
            shouldShowShareButton
            shouldShowTranscript
            shouldShowChapter
            shouldShowFile
            shouldShowPoll
            pageTitle
            pageText
            pageTheme
            pageLogoKey
            pageLogoKey
            pageLogoCdnUrl
            accentColor
            watermarkEnabled
            watermarkPosition
            watermarkLink
            watermarkImageKey
            watermarkImageCdnUrl
          }
          embedSetting {
            id
            startsAt
            shouldShowSummary
            shouldShowDiscussion
            shouldShowShareButton
            shouldShowTranscript
            shouldShowChapter
            shouldShowFile
            shouldShowPoll
            pageTitle
            pageText
            pageTheme
            pageLogoKey
            pageLogoKey
            pageLogoCdnUrl
            accentColor
            watermarkEnabled
            watermarkPosition
            watermarkLink
            watermarkImageKey
            watermarkImageCdnUrl
          }
        }
      }
    }
  }
`;

export const GET_USER_PREFERENCES = gql`
  query getUserPreferences {
    me {
      id
      preferences {
        id
        pageLogoKey
        pageTheme
        accentColor
        shouldShowSummary
        shouldShowShareButton
        shouldShowTranscript
        shouldShowChapter
        vodIsPublished
        vodPrivacity
        vodSelectedThumbKey
        vodSelectedThumbCdnUrl
        liveIsPublished
        livePrivacity
        liveSelectedThumbKey
        liveSelectedThumbCdnUrl
        watermarkEnabled
        watermarkPosition
        watermarkLink
        watermarkImageKey
        watermarkImageCdnUrl
        pageSetting {
          id
          startsAt
          shouldShowSummary
          shouldShowDiscussion
          shouldShowShareButton
          shouldShowShareEmbed
          shouldShowTranscript
          shouldShowChapter
          shouldShowFile
          shouldShowPoll
          pageTitle
          pageText
          pageTheme
          pageLogoKey
          pageLogoCdnUrl
          accentColor
          watermarkEnabled
          watermarkPosition
          watermarkLink
          watermarkImageKey
          watermarkImageCdnUrl
        }
        embedSetting {
          id
          startsAt
          shouldShowSummary
          shouldShowDiscussion
          shouldShowShareButton
          shouldShowTranscript
          shouldShowChapter
          shouldShowFile
          shouldShowPoll
          pageTitle
          pageText
          pageTheme
          pageLogoKey
          pageLogoCdnUrl
          accentColor
          watermarkEnabled
          watermarkPosition
          watermarkLink
          watermarkImageKey
          watermarkImageCdnUrl
        }
      }
    }
  }
`;

export const GET_USER_BY_ROLES = gql`
  query GetUserByRoles($roleNames: [UserRole]) {
    getUserByRoles(roleNames: $roleNames) {
      id
      firstName
      lastName
      roles {
        id
        name {
          title
        }
      }
    }
  }
`;
