import { useEffect } from "react";
import { RecoilRoot } from "recoil";
// import ReactDOM from "react-dom";
import { BrowserRouter, useLocation } from "react-router-dom";
// React 18
import { StyleProvider } from "@ant-design/cssinjs";
import { ApolloProvider } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { ConfigProvider } from "antd";
import locale from "antd/lib/locale/fr_FR";
import "dayjs/locale/fr";
import { createRoot } from "react-dom/client";
import "./i18n";
import "./index.css";
import Body from "./sections/Body";
import * as serviceWorker from "./serviceWorker";

import SentryReporter from "./commons/SentryReporter";
import { antdTheme } from "./styles/antd-theme";
import client from "./utils/graphQLConfig/backoffice-config.js";
// import Maintenance from "./sections/landing/Maintenance";
import Cookies from "js-cookie";
import { getSentryEnabled } from "./utils/functions/sentry";
// import Maintenance from "./sections/landing/Maintenance";

const sentryEnabled = getSentryEnabled();

if (sentryEnabled) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENVIRONMENT,
    includeLocalVariables: true,
  });
}

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const isAllowed = window.location.href.includes("iamthemigrater");
isAllowed && Cookies.set("isAllowed", true);
const isAllowedCookie = Cookies.get("isAllowed");
// React 18
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <RecoilRoot>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <ScrollToTop />
        <SentryReporter>
          <ConfigProvider locale={locale} theme={antdTheme}>
            {/*  <React.StrictMode> */}
            <StyleProvider hashPriority="hight">
              <Body />
            </StyleProvider>
            {/*  </React.StrictMode> */}
          </ConfigProvider>
        </SentryReporter>
      </BrowserRouter>
    </ApolloProvider>
  </RecoilRoot>,
);

// if (!isAllowedCookie) {
//   root.render(<Maintenance />, document.getElementById("root"));
// } else {
//   root.render(
//     <RecoilRoot>
//       <ApolloProvider client={client}>
//         <BrowserRouter>
//           <ScrollToTop />
//           <SentryReporter>
//             <ConfigProvider locale={frFR} theme={antdTheme}>
//               <React.StrictMode>
//                 <StyleProvider hashPriority="hight">
//                   <Body />
//                 </StyleProvider>
//                 <ToastContainer closeButton={false} />
//               </React.StrictMode>
//             </ConfigProvider>
//           </SentryReporter>
//         </BrowserRouter>
//       </ApolloProvider>
//     </RecoilRoot>
//   );
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
