import React from "react";
import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";

const defaultBaseColor = "#d8d8d8";

const defaultHighlightColor = "#888b94";

const skeletonKeyframes = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

const SkeletonElem = styled("span")`
  background-color: ${defaultBaseColor};
  background-image: linear-gradient(
    90deg,
    ${defaultBaseColor},
    ${defaultHighlightColor},
    ${defaultBaseColor}
  );
  opacity: 0.6;
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  line-height: 1;
  width: 100%;
  min-width: 40px;
  ${(props) => props.css};
`;

export default function Skeleton({
  count,
  duration,
  width,
  wrapper: Wrapper,
  height,
  circle,
}) {
  const elements = [];

  for (let i = 0; i < count; i++) {
    let style = {};

    if (width !== null) {
      style.width = width;
    }

    if (height !== null) {
      style.height = height;
    }

    if (width !== null && height !== null && circle) {
      style.borderRadius = "50%";
    }
    elements.push(
      <SkeletonElem
        key={i}
        className="skeleton"
        style={style}
        css={css`
          animation: ${skeletonKeyframes} ${duration}s ease infinite;
        `}
      >
        &zwnj;
      </SkeletonElem>
    );
  }

  return (
    <span>
      {Wrapper
        ? elements.map((element, i) => (
            <Wrapper key={i}>
              {element}
              &zwnj;
            </Wrapper>
          ))
        : elements}
    </span>
  );
}

Skeleton.defaultProps = {
  count: 1,
  duration: 3,
  width: null,
  wrapper: null,
  height: null,
  circle: false,
};
