import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_WEB_TV,
  CREATE_WEB_TV_LINK,
  DELETE_WEB_TV,
  DELETE_WEB_TV_LINK,
  REORDER_WEB_TV_LINKS,
  UPDATE_WEB_TV,
  UPDATE_WEB_TV_CONTACT,
  UPDATE_WEB_TV_DOMAIN,
  UPDATE_WEB_TV_LINK,
} from "../mutations/webTV";
import {
  GET_WEBTV,
  GET_WEBTV_ABOUT,
  GET_WEBTV_CONTACT,
  GET_WEBTV_CREATION_DATE,
  GET_WEBTV_LINKS,
  GET_WEBTV_SECTIONS,
  GET_WEBTV_SHARE_INFOS,
} from "../queries/webTV";

/***************/
/*** Queries ***/
/***************/

export const GetWebTVInfos = (id) => {
  const { data, loading, error, refetch } = useQuery(GET_WEBTV, {
    variables: { id },
  });
  const availableData = data && data.webTV;
  if (!availableData) {
    return {
      loading,
      hasData: availableData,
    };
  }
  return {
    availableData,
    loading,
    error,
    refetch,
  };
};

export const GetWebTVShareInfos = (id, skip) => {
  const { data, loading, error, refetch } = useQuery(GET_WEBTV_SHARE_INFOS, {
    variables: { id },
    skip,
  });
  const availableData = data && data.webTV;

  return {
    availableData,
    loading,
    error,
    refetch,
  };
};

export const GetWebTVAbout = (id) => {
  const { data, loading, error, refetch } = useQuery(GET_WEBTV_ABOUT, {
    variables: { id },
  });
  const availableData = data && data.webTV.about;
  const webTV = data && data.webTV;

  if (!availableData) {
    return {
      loading,
      hasData: availableData,
    };
  }
  return {
    availableData,
    webTV,
    loading,
    error,
    refetch,
  };
};
export const GetWebTVContact = (id) => {
  const { data, loading, error, refetch } = useQuery(GET_WEBTV_CONTACT, {
    variables: { id },
  });
  const availableData = data && data.webTV.contactPage;
  const webTV = data && data.webTV;
  if (!availableData) {
    return {
      loading,
      hasData: availableData,
    };
  }
  return {
    availableData,
    webTV,
    loading,
    error,
    refetch,
  };
};

export const GetWebTVLinks = (id) => {
  const { data, loading, error, refetch } = useQuery(GET_WEBTV_LINKS, {
    variables: { id },
  });
  const availableData = data && data?.webTV;
  if (!availableData) {
    return {
      loading,
      hasData: availableData,
    };
  }
  return {
    availableData,
    loading,
    refetch,
    error,
  };
};

export const GetWebTVCreationDate = (id, skip) => {
  const { loading, error, data } = useQuery(GET_WEBTV_CREATION_DATE, {
    variables: {
      webTVID: id,
    },
    skip,
  });

  const createdAt = data?.webTV?.createdAt;

  return {
    createdAt,
    loading,
    error,
  };
};

export const GetWebTVSections = (id, skip) => {
  const { loading, error, data } = useQuery(GET_WEBTV_SECTIONS, {
    variables: {
      id,
    },
    skip,
  });

  return {
    webTV: data?.webTV,
    loading,
    error,
  };
};

/*****************/
/*** Mutations ***/
/*****************/

export const useCreateWebTV = () => {
  const [createWebTV, { loading }] = useMutation(CREATE_WEB_TV);
  return {
    createWebTVLoading: loading,
    createWebTV: () => createWebTV(),
  };
};

export const useUpdateWebTV = () => {
  const [updateWebTV, { loading }] = useMutation(UPDATE_WEB_TV);
  return {
    updateWebTVLoading: loading,
    updateWebTV: (variables) => updateWebTV(variables),
  };
};

export const useUpdateWebTVContact = () => {
  const [updateWebTVContact, { loading }] = useMutation(UPDATE_WEB_TV_CONTACT);
  return {
    updateWebTVContactLoading: loading,
    updateWebTVContact: (variables) => updateWebTVContact(variables),
  };
};

export const useDeleteWebTV = () => {
  const [deleteWebTV] = useMutation(DELETE_WEB_TV);
  return async (id) => {
    try {
      const { data } = await deleteWebTV(id);
      return {
        data,
      };
    } catch (error) {
      return { error };
    }
  };
};

export const useDeleteWebTVLink = (webTVId) => {
  const [deleteWebTVLink] = useMutation(DELETE_WEB_TV_LINK, {
    refetchQueries: [
      {
        query: GET_WEBTV_LINKS,
        variables: {
          id: webTVId,
        },
      },
    ],
  });
  return async (variables) => {
    try {
      const { data } = await deleteWebTVLink({
        variables,
      });
      return {
        data,
      };
    } catch (error) {
      return { error };
    }
  };
};

export const useUpdateWebTVLink = () => {
  const [updateWebTVLink] = useMutation(UPDATE_WEB_TV_LINK);
  return async ({ variables }) => {
    try {
      const { data } = await updateWebTVLink({
        variables,
      });
      return {
        data,
      };
    } catch (error) {
      return { error };
    }
  };
};

export const useReorderWebTVLinks = () => {
  const [reorderWebTVLinks, { loading }] = useMutation(REORDER_WEB_TV_LINKS);
  return {
    reorderWebTVLinksLoading: loading,
    reorderWebTVLinks: (variables) => reorderWebTVLinks({ variables }),
  };
};

export const useCreateWebTVLink = () => {
  const [createWebTVLink] = useMutation(CREATE_WEB_TV_LINK);
  return async ({ variables }) => {
    try {
      const { data } = await createWebTVLink({
        variables,
      });
      return {
        data,
      };
    } catch (error) {
      return { error };
    }
  };
};

export const useUpdateWebTVDomain = () => {
  const [updateWebTVDomain] = useMutation(UPDATE_WEB_TV_DOMAIN);
  return async (variables) => {
    const { data } = await updateWebTVDomain({
      variables,
    });
    return {
      data,
    };
  };
};
