import { gql } from "@apollo/client"; // eslint-disable-line import/no-extraneous-dependencies

export const GET_WEBTV = gql`
  query getWebTvData($id: ID!) {
    webTV(id: $id) {
      id
      identifier
      isPublished
      domain
      note
      gtmId
      ga4Id
      matomoCloudId
      matomoCloudUrl
      matomoCloudJsPath
      matomoSelfHostedId
      matomoSelfHostedUrl
      createdAt
      account {
        id
      }
      publisher {
        id
        firstName
        lastName
        pictureCdnUrl
      }
      id
      identifier
      isPublished
      domain

      links {
        id
        label
        url
      }
      contactPage {
        id
        phone
        email
        address
        country
        email
        websiteUrl
      }
      about {
        id
        presentation {
          id
          title
          description
        }
      }
      rollOption
      preRoll {
        id
        name
        isPublished
      }
      postRoll {
        id
        name
        isPublished
      }
      template {
        id
        extraFooter
        templateSettings {
          id
          startsAt
          shouldShowSummary
          shouldShowDiscussion
          shouldShowShareButton
          shouldShowTranscript
          shouldShowChapter
          shouldShowFile
          shouldShowPoll
          pageTitle
          pageText
          videoTitle
          pageTheme
          pageLogoKey
          accentColor
          watermarkEnabled
          watermarkPosition
          watermarkLink
          shouldShowSideBar
        }
        name {
          name
        }
        presentation {
          id
          title
          description
          bannerCdnUrl
        }
        highlight {
          id
          live {
            id
          }
          media {
            id
          }
          iframe {
            id
          }
        }
      }
      groups {
        id
        order
        highlight {
          id
          order
          live {
            id
          }
          media {
            id
          }
          iframe {
            id
          }
        }
        sections {
          id
        }
      }
      sections {
        id
        order
        presentation {
          id
          title
          description
        }
      }
      rollOption
      preRoll {
        id
        name
      }
      postRoll {
        id
        name
      }
    }
  }
`;

export const GET_WEBTV_SHARE_INFOS = gql`
  query getWebTvInfos($id: ID!) {
    webTV(id: $id) {
      id
      template {
        id
        templateSettings {
          id
          pageTitle
          pageText
          videoTitle
        }
        presentation {
          id
          title
          description
          bannerCdnUrl
        }
      }
    }
  }
`;

export const GET_WEBTV_ABOUT = gql`
  query getWebTvAbout($id: ID!) {
    webTV(id: $id) {
      id
      account {
        id
      }
      about {
        id
        presentation {
          id
          title
          bannerKey
          bannerCdnUrl
          description
          isPublished
          heroBannerMode
          heroBannerHeight
          heroBannerPosition
          heroBannerType
          media {
            id
            selectedThumbCdnUrl
          }
        }
        isPublished
      }
    }
  }
`;

export const GET_WEBTV_CONTACT = gql`
  query getWebTvContact($id: ID!) {
    webTV(id: $id) {
      id
      account {
        id
      }
      contactPage {
        id
        isPublished
        phone
        address
        city
        postalCode
        country
        websiteUrl
        email
        presentation {
          id
          title
          description
          bannerKey
          bannerCdnUrl
          isPublished
          heroBannerMode
          heroBannerHeight
          heroBannerPosition
          heroBannerType
          media {
            id
            selectedThumbCdnUrl
          }
        }
      }
    }
  }
`;

export const GET_WEBTV_LINKS = gql`
  query getWebTvLinks($id: ID!) {
    webTV(id: $id) {
      id
      template {
        id
        showLinksInHeader
      }
      links {
        id
        order
        url
        label
        publisher {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const GET_WEBTV_CREATION_DATE = gql`
  query getWebTVCreationDate($webTVID: ID!) {
    webTV(id: $webTVID) {
      id
      createdAt
    }
  }
`;

export const GET_WEBTV_TITLE = gql`
  query getWebTVTitle($webTVId: ID!) {
    webTV(id: $webTVId) {
      id
      template {
        id
        presentation {
          id
          title
        }
      }
    }
  }
`;

export const GET_WEBTV_SECTIONS = gql`
  query getWebTVContents($id: ID!) {
    webTV(id: $id) {
      id
      sections {
        id
        slug
        presentation {
          id
          title
        }
        sectionContents(limit: 1) {
          totalCount
        }
      }
    }
  }
`;
