import styled from "@emotion/styled";
import { Navigate } from "react-router-dom";

const LandingMain = styled("div")`
  background-color: ${(props) => "#f8f8f8"};
  color: ${(props) => "#37374b"};
`;

const Landing = () => {
  return (
    <LandingMain>
      <Navigate to={"/app"} />
    </LandingMain>
  );
};
export default Landing;
